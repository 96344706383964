@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}
/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 4px; /* Width of the vertical scrollbar */
  height: 4px; /* Height of the horizontal scrollbar */
}
::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #edf2f7;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.PhoneInputInput {
  width: 100%;
  height: 54px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 1px solid #D1D5DB;
  border-bottom: 4px solid #D1D5DB;
  border-right: 1px solid #D1D5DB;
  border-left: 0px;
  outline: none;
}

.PhoneInputInput:focus {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 1px solid #00c5ff;
  border-bottom: 4px solid #00c5ff;
  border-right: 1px solid #00c5ff;
  border-left: 0px;
  outline: none;
}

.PhoneInputCountry {
  margin-right: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top: 1px solid #D1D5DB;
  border-bottom: 4px solid #D1D5DB;
  border-right: 0px !important;
  border-left: 1px solid #D1D5DB;
  outline: none;
}

.PhoneInputCountry:focus {
  border-radius: 0.5rem;
  border: 1px solid #00c5ff;
  border-bottom: 4px solid #00c5ff;
  border-color: transparent;
  border-color: #00c5ff;
}

.PhoneInput:focus-within .PhoneInputCountry {
  border-color: #00c5ff;
  border-bottom: 4px solid #00c5ff;
  outline: none;
}

.searchWrapper{
  width: 100%;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 0.62rem !important;
  padding-bottom: 0.62rem !important;
  
  border-radius: 0.5rem !important;
  border-top: 1px solid #D1D5DB !important;
  border-bottom: 4px solid #D1D5DB !important;
  border-right: 1px solid #D1D5DB !important;
  border-left: 1px solid #D1D5DB !important;
  outline: none;
  margin-top: 4px;
}
/* 
.searchWrapper{
  border: 0px !important;
  border-radius: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
  position: relative;
}
.multiSelectContainer input{
  width: 100%;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  
  border-radius: 0.5rem;
  border-top: 1px solid #D1D5DB !important;
  border-bottom: 4px solid #D1D5DB !important;
  border-right: 1px solid #D1D5DB !important;
  border-left: 1px solid #D1D5DB !important;
  outline: none;
}

.multiSelectContainer input:focus {
  border-top: 1px solid #00c5ff !important;
  border-bottom: 4px solid #00c5ff !important;
  border-right: 1px solid #00c5ff !important;
  border-left: 1px solid #00c5ff !important;
  outline: none;
} */
